@import '../../../styles/common';
@import '../../../styles/andes_components';

@import '~ui-library_ml/src/components/carousel-draggable/styles/draggable';
@import '~ui-library_ml/src/components/carousel/styles/paged';
@import '~@ui-autoparts-web/compats/src/styles/mobile';
@import '~cpg-nordic/src/styles/cpg.mobile';
@import '~@mshops-components-library/modal-add-to-cart/src/styles/iframe-modal.mobile';
@import '~@seo-frontend-components/card-blog-carousel-mobile/index';
@import '~@seo-frontend-components/card-blog-entry/index';
@import '~@seo-frontend-components/extended-menu-mobile/index';
@import '~@seo-frontend-components/dynamic-accordions-links/index';
@import '~@seo-frontend-components/keyword-summary-mobile/index';

@import '../../../components-v2/carousel/styles/carousel.mobile';
@import '../../../components-v2/modal/styles/mobile';
@import '../../../components/cross-site-modal/styles/cross-site-modal.mobile';
@import '../../../components-v2/icons/styles/mobile';
@import '../../../components/card/price/styles/price.mobile';
@import '../../../components/filters/applied/styles/filters.mobile';
@import '../../../components/filters/available/styles/filters.mobile';
@import '../../../components-v2/money-picker/styles/mobile';
@import '../../../components/toolbar/styles/toolbar.mobile';
@import '../../../components/view-change/styles/view-change.mobile';
@import '../../../components/layout/styles/layout.mobile';
@import '../../../components-v2/bookmark/styles/bookmark';
@import '../../../components/pagination/styles/pagination.mobile';
@import '../../../components/top-keywords/styles/top-keywords.mobile';
@import '../../../components-v2/header/styles/official-store-header.mobile';
@import '../../../components-v2/header/styles/seller-header.mobile';
@import '../../../components-v2/header/styles/deal-header.mobile';
@import '../../../components/card/attributes/styles/attributes.mobile';
@import '../../../components/card/fulfillment/styles/fulfillment';
@import '../../../components/card/highlight/styles/highlight-label.mobile';
@import '../../../components/billboard/styles/billboard.mobile';
@import '../../../components/spotlight/styles/spotlight.mobile';
@import '../../../components/alert/styles/alert.mobile';
@import '../../../components/reviews/styles/reviews.mobile';
@import '../../../components/listing-disclaimer/styles/listing-disclaimer.mobile';
@import '../../../components-v2/footer-disclaimer/styles/footer-disclaimer.mobile';
@import '../../../components/tooltip/styles/tooltip.mobile';
@import '../../../components/main-header/styles/main-header.mobile';
@import '../../../components-v2/related-searches/styles/related-searches.mobile';
@import '../../../components/card/add-to-cart/styles/add-to-cart.mobile';
@import '../../../components/snackbar/styles/snackbar.mobile';
@import '../../../components/full-snackbar/styles/full-snackbar.mobile';
@import '../../../components/full-snackbar/styles/full-shipping-info-skeleton.scss';
@import '../../../components/full-snackbar/styles/full-popup.mobile';
@import '../../../components/animated-switch/styles/animated-switch';
@import '../../../components/animated-switch/styles/animated-switch-v2.mobile';
@import '../../../components/top-switch-filter/styles/top-switch-filter.mobile';
@import '../../../components/intervention/andes-message-intervention/styles/andes-message-intervention.mobile';
@import '../../../components/info-text-box/styles/info-text-box.mobile';
@import '../../../components-v2/sidebar/components/filters-tooltip/styles/mobile';
@import '../../../components/collapsible-card/styles/collapsible-card.mobile';
@import '../../../components/collapsible-card-list/collapsible-card-list';
@import '../../../components/seo-contents/styles/seo-contents.mobile';
@import '../../../components-v2/footer-components/styles/mobile';
@import '../../../components-v2/sidebar/components/breadcrumb/styles/breadcrumb.mobile';
@import '../../../components-v2/pricing-banner/styles/pricing-banner.mobile';
@import '../../../components-v2/sidebar/components/map/styles/map-link.mobile.scss';
@import '../../../components/value-propositions-details/styles/value-propositions-details-modal.mobile';
@import '../../../components/boleto-discount-message/styles/boleto-discount-message.mobile';
@import '../../../components-v2/intervention/filter-intervention/styles/filter-intervention.mobile';
@import '../../../components/intervention/quick-access-intervention/styles/mobile';
@import '../../../components-v2/intervention/content/styles/content-intervention.mobile';
@import '../../../components-v2/intervention/seo-keyword-summary-intervention/styles/seo-keyword-summary-intervention.mobile';
@import '../../../components-v2/intervention/seo-info-image-text-box-intervention/styles/mobile';
@import '../../../components-v2/intervention/billboard/styles/billboard-intervention.mobile';
@import '../../../components/intervention/shopping-cart/styles/shopping-cart-intervention';
@import '../../../components/intervention/shopping-cart/styles/shopping-cart-intervention.mobile';
@import '../../../components-v2/icons/svg-sprites/styles/index';
@import '../../../components/carousel-search/styles/carousel-search.mobile';
@import '../../../components/sort/styles/content.mobile';
@import '../../../components/sort/styles/sort.mobile.scss';
@import '../../../components/compats/styles/index';
@import '../../../components/coachmark/styles/coachmark.mobile';
@import '../../../components/brand-ads/styles/brand-ads.mobile';
@import '../../../components-v2/intervention/meliplay/styles/mobile/index.scss';
@import '../../../components-v2/intervention/seo-top-keywords-intervention/styles/seo-top-keywords-intervention.mobile';
@import '../../../components-v2/intervention/filter-intervention/styles/text-icon-carousel.mobile';
@import '../../../components/intervention/meliplay/styles/mobile/index.scss';
@import '../../../components-v2/intervention/trend-intervention/styles/index';
@import '../../../components/pills-builder/styles/pill.mobile';
@import '../../../components-v2/intervention/brand-disco/styles/brand-disco.mobile';
@import '../../../components/intervention/more-info-intervention/styles/more-info-intervention.mobile';
@import '../../../components/seo-contents/styles/seo-contents.mobile';
@import '../../../components/seo-smart-tabs/styles/seo-smart-tabs.mobile';
@import '../../../components/seo-contents/styles/seo-extended-menu.mobile';
@import '../../../components/advertising-adn/styles/advertising-adn';
@import '../../../components-v2/sidebar/styles/search-result.mobile';
@import '../../../components/intervention/four-stars/styles/four-stars-intervention';

@import '../../../components-v2/eshops/styles/eshops.mobile';

body {
  background-color: $search-bg-color;
}

// sass-lint:disable no-vendor-prefixes, no-qualifying-elements
main[role='main'] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  flex-grow: 0;
}

.andes-dropdown.ui-compats .andes-floating-menu {
  opacity: 1;
}
