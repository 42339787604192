@import '../../../styles/variables';
@import '../../../styles/mixins';
@import './gallery.mobile';
@import './stack.mobile';
@import './mosaic.mobile';
@import './map.mobile';
@import './grid.mobile';

.#{$search-prefix}-item__highlighted-bottom.flex-end {
  justify-content: flex-end;
}

.#{$search-prefix}-item__highlighted-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .#{$search-prefix}-item__highlight-label--media {
    padding-bottom: 0 !important;
  }

  .#{$search-prefix}-item__highlighted {
    color: #3483fa !important;
  }
}

.#{$search-prefix}-item__operation {
  font-weight: $font-weight-normal;
  font-size: $andes-spacing-12;
  color: $search-gray-r050;
  margin-bottom: 3px;
}

.#{$search-prefix}-item__attributes--res-grid li {
  line-height: $line-height-18;
  font-weight: $font-weight-normal;
  font-size: $font-size-14;
  color: $search-gray-r090;
  display: inline;
  &:not(:first-child)::before {
    content: '|';
    vertical-align: baseline;
    font-size: $font-size-12;
    margin: 0 3px;
  }
}

.#{$search-prefix}-item__title-development {
  font-size: 14px;
  font-weight: $font-weight-normal;
  color: $search-gray-r080;
}

.#{$search-prefix}-item__price-label {
  font-size: $andes-spacing-12;
  font-weight: $font-weight-normal;
  margin-top: 10px;
}

.#{$search-prefix}-item__highlight-group-label {
  margin-top: $andes-spacing-8;
}

.#{$search-prefix}-item__description-container {
  .#{$search-prefix}-item__location {
    font-size: 14px;
  }
}

.#{$search-prefix}-item__possession-wrapper {
  .#{$search-prefix}-item__possession {
    color: $search-gray-r050 !important;
    font-size: $font-size-14;
    line-height: $line-height-18;
    font-weight: $font-weight-normal;
  }
}

.#{$search-prefix}-item__official-store-container {
  margin-top: $andes-spacing-16;
  .#{$search-prefix}-official-store-label {
    font-size: $font-size-14;
  }
}

.#{$search-prefix}-item__rental-type-container {
  .#{$search-prefix}-item__group--rental-type {
    margin-bottom: 5px !important;
  }
}

.#{$search-prefix}-item__available-units-container {
  .#{$search-prefix}-item__available-units {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    color: $andes-gray-550;
    line-height: $line-height-18;
  }
}

.#{$search-prefix}-item__price-label {
  .andes-money-amount__fraction,
  .andes-money-amount__currency-symbol {
    font-size: $font-size-24 !important;
    font-weight: $font-weight-normal;
    color: $search-gray-r090;
  }
}

// sass-lint:disable no-vendor-prefixes

.#{$search-prefix}-result {
  background-color: $andes-white;
}

.#{$search-prefix}-item__brand-discoverability {
  @include text-crop(4px, 0);

  color: $search-text-color;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: -$search-spacing-8 !important;
}

.#{$search-prefix}-item__title {
  @include text-crop(4px, 0);

  color: $search-text-color;
  font-size: $font-size-14;
  font-weight: 500;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.#{$search-prefix}-item__price_label {
  @include text-crop(6px, 5px);

  color: $search-text-color;
  font-size: 17px;
  font-weight: $font-weight-regular;
}

.#{$search-prefix}-item__pub-label {
  font-size: $font-size-14;
  line-height: 16px;
  color: $search-ad-label-color;
  position: absolute;
  bottom: $andes-spacing-12;
  right: $andes-spacing-12;

  &--blue {
    color: $andes-blue-500;
  }
}

.#{$search-prefix}-layout {
  list-style: none;
  list-style-type: none;

  &--fade {
    opacity: 0.5;
    transition: opacity 1s ease-in-out;
  }

  &__item {
    list-style: none;
    list-style-type: none;
    position: relative;
  }

  .#{$search-prefix}-item__shipping {
    @include text-crop(3px, 3px);

    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    line-height: $font-size-14;
  }

  .#{$search-prefix}-item__shipping--free {
    font-weight: $font-weight-semibold;
  }

  .#{$search-prefix}-price {
    color: $search-text-color;

    &__second-line {
      display: -webkit-flex;
      display: flex;
      align-items: center;
    }

    &__second-line__label {
      color: $search-green-100;
      font-size: $font-size-12;
      font-weight: $font-weight-regular;
      white-space: nowrap;
    }

    .price-tag-cents {
      font-size: $font-size-10;
      font-weight: $font-weight-semibold;
    }

    &__second-line--decimal .price-tag-cents {
      padding-top: 0;
      font-size: inherit;
      font-weight: inherit;
      line-height: unset;
    }

    .#{$search-prefix}-price__second-line .andes-money-amount__fraction,
    .andes-money-amount__currency-symbol {
      font-size: $font-size-20;
    }
  }

  .#{$search-prefix}-installments {
    .#{$search-prefix}-price {
      display: inline-block;
      color: inherit;

      &__part {
        margin-right: 0;
      }

      .price-tag-cents {
        margin-left: 1px;
        padding-top: 0;
        font-size: $font-size-8;
      }
    }

    .#{$search-prefix}-price__second-line .andes-money-amount__fraction,
    .andes-money-amount__currency-symbol {
      font-size: $font-size-12;
    }
  }

  .#{$search-prefix}-result__title {
    overflow: hidden;
  }

  .#{$search-prefix}-item__location {
    @include text-crop(4px, 4px);

    display: block;
  }
}

.#{$search-prefix}-item__group {
  margin-bottom: $andes-spacing-12;

  &--title {
    margin-bottom: $andes-spacing-12;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.ui-search-item__group.ui-search-item__group--title.shops__items-group {
    margin-bottom: 6px;
  }
}

.#{$search-prefix}-item__group__element {
  margin-bottom: $andes-spacing-8;

  &.ui-search-price {
    margin-bottom: $search-spacing-6;

    &.ui-search-item__pds-best-price {
      margin-bottom: $andes-spacing-8;
    }
  }

  .#{$search-prefix}-item__shipping {
    margin-right: $andes-spacing-4;
  }

  .#{$search-prefix}-item__promise {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .#{$search-prefix}-item__promise .#{$search-prefix}-item__fulfillment {
    display: inline-block;
    margin-top: 3px;
  }

  .#{$search-prefix}-item__promise span:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }

  .#{$search-prefix}-item__promise__text--last {
    margin-right: $andes-spacing-4;
  }

  .#{$search-prefix}-item__promise__text--long {
    margin-bottom: 0;
  }

  .#{$search-prefix}-item__fulfillment {
    display: inline-flex;
    height: 10px;
    margin: -1px 0;
  }

  &.#{$search-prefix}-price-details:last-child {
    margin-bottom: -$search-spacing-10;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.#{$search-prefix}-billboard__price {
  width: 100%;
  position: absolute;

  &::before,
  &::after {
    content: none;
  }

  .#{$search-prefix}-price__second-line {
    display: block;
  }

  .#{$search-prefix}-price__part {
    display: inline-block;
    margin-right: 0;
  }
}

.#{$search-prefix}-billboard__price--mot {
  font-size: 26px;
  top: 71px;

  .#{$search-prefix}-price__part {
    font-weight: 100;
  }
}

.#{$search-prefix}-billboard__price--res {
  font-size: 30px;
  top: 82px;

  .#{$search-prefix}-price__part {
    font-weight: $font-weight-regular;
  }
}

.#{$search-prefix}-item__pds-best-price {
  @include text-crop(2px, 2px);
  .#{$search-prefix}-price__part {
    font-weight: $font-weight-semibold;
  }
}

.#{$search-prefix}-billboard__bookmark {
  .#{$search-prefix}-bookmark__btn::before {
    content: '';
    background: rgba($andes-white, 0.7);
    width: 31px;
    height: 31px;
    position: absolute;
    top: 7px;
    right: 9px;
    border-radius: 50%;
    z-index: $z-index-base;
  }

  .#{$search-prefix}-icon--bookmark {
    z-index: $z-index-s;
  }
}

.#{$search-prefix}-item__highlight-label {
  &.#{$search-prefix}-value-proposition__pill {
    margin-bottom: 0;
  }
}

.ui-row-card .ui-row-card__link--pill-p .ui-row-item__ad-label {
  position: relative;
  padding: 8px 12px 0 8px;
  float: right;
  bottom: unset;
  right: unset;
}

// sass-lint:disable bem-depth, class-name-format
.ui-row-item__shipping__highlight.ui-row-item__highlight-label--shipping_guaranteed,
.#{$search-prefix}-item__shipping__highlight.#{$search-prefix}-item__highlight-label--shipping_guaranteed {
  font-size: 12px;
}

.#{$search-prefix}-result__highlight-container {
  padding-bottom: $andes-spacing-4;
  margin-bottom: 0;

  .#{$search-prefix}-item__highlight-label {
    font-size: $font-size-10;
    font-weight: $font-weight-semibold;
    padding: $search-spacing-2 $search-spacing-6;
    border-radius: $search-spacing-2;
    line-height: $line-height-base;
    margin: 0;

    &--TIME_LABEL_HIGHLIGHT {
      font-size: $font-size-12;
    }
  }
}

.#{$search-prefix}-item__group--rebates {
  .#{$search-prefix}-item__highlight-label {
    &__container {
      padding: 0;
    }
  }
}

.#{$search-prefix}-item__available-units-wrapper {
  margin-bottom: $andes-spacing-4;
}

.#{$search-prefix}-item__available-units-wrapper .#{$search-prefix}-item__available-units {
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  color: $andes-gray-550;
  line-height: 15px;
  display: block;
  margin-top: 1px;
  margin-bottom: $andes-spacing-12;
}
